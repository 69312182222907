import React, { useEffect, useState } from 'react';
import { updateExpressionStatement } from 'typescript';
// import logo from './logo.svg';
// import './App.css';

function App() {
  const [data, setData] = useState('');
  const [insertText, setInsertText] = useState<string>('');
  const apiUrl: string = 'https://cute-mountain-philosophy.glitch.me'; // mock api on glitch
  // const apiUrl: string = 'http://localhost:3001';

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const request = await fetch(`${apiUrl}/list`);
    const d = await request.json();
    setData(d);
  };

  const clearData = () => setData('');

  const handleText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInsertText(e.target.value);
  };

  const submitTodo = async () => {
    const request = await fetch(`${apiUrl}/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text: insertText,
      }),
    });
    const d = await request.json();
    alert(d.message);
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          L'app è in modalità: <strong>{navigator.onLine ? 'Online' : 'Offline'}</strong>
        </p>
        <div>
          <button onClick={getData}>Lista TODO</button>
          <button onClick={clearData}>Clear</button>
        </div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
        <input type="text" onChange={handleText} value={insertText} />
        <button onClick={submitTodo}>Aggiungi todo</button>
      </header>
    </div>
  );
}

export default App;
